import React, { useRef, useState } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography'
import { makeStyles, themes, withStyles } from '@material-ui/core/styles';
import {Link as LinkRouter} from "react-router-dom";

import PaymentForm from './PaymentForm';

import 'fontsource-roboto';
import '../App.css';
//import { AccordionDetails } from '@material-ui/core';


const DEBUG = 0;
let baseURL = '';
let numTrialDays;

if(DEBUG===1) {
    baseURL = "http://localhost:5000/";           // TODO: change before release
    numTrialDays = 1;
} else {
    baseURL = "https://myadfreeplayer.xyz/"
    numTrialDays = 7;
}

//let usernameInput = '';
let usernameInputReset = '';
const StyledButtonTop = withStyles({
  root: {
    background: 'rgb(25, 25, 25, 0)',
    borderRadius: 3,
    border: 0,
    color: '#24D888',
    fontFamily: 'Oswald', 
    fontWeight: 'bold', 
    fontSize: 24,
    height: 48,
    padding: '0 20px',
    position: 'absolute',
    top: '10px !important',
    left: '10px !important',
    textShadow: '1px 1px 0px rgba(0,0,0,0.8)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export const Account = ({ changePage }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [resetPW, setResetPW] = useState(false);
   // const [badEmail, setBadEmail] = useState(false);
   // const [badPw, setBadPW] = useState(false);
   // const [badConfPW, setBadConfPW] = useState(false);
    const [acctDetails, setAcctDetails] = useState({});
    const [acctEmail, setAcctEmail] = useState('');
    const [gCustomer, setGCustomer] = useState({id:0,email:'',password:'',licenseID:''});
    const [gProduct, setGProduct] = useState({key:0,price:"$5.00",name:"My Ad-Free Player License",interval:"month",billed:"monthly"});
    const [gRestartingSub, setGRestartingSub] = useState(false);
    const [gCanceledState, setGCanceledState] = useState("");
    const [gGoodUntil, setGGoodUntil] = useState("");
    const [gHideButton, setGHideButton] = useState(false);
    const [gHideDetails, setGHideDetails] = useState(false);
    const [chrgFail, setChrgFail] = useState(false);
    const [failDate, setFailDate] = useState("");
    const inputUser = useRef(null);

    const stripeProduct = {
        key: 0,
        price: '$5.00',
        amount: 500, 
        name: 'My Ad-Free Player License',
        interval: 'month',
        billed: 'monthly',
    };

    let customer = {
        id: 0,
        email: '',
        password: '',
        licenseID: '',
        trialEnd: null,
        restarting: false,
    };
    const StyledButtonTop = withStyles({
        root: {
          background: 'rgb(25, 25, 25, 0)',
          borderRadius: 3,
          border: 0,
          color: '#24D888',
          fontFamily: 'Oswald', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          position: 'absolute',
          top: '10px !important',
          left: '10px !important',
          textShadow: '1px 1px 0px rgba(0,0,0,0.8)',
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);


      const StyledButtonCont = withStyles({
        root: {
          background: '#24D888',
          borderRadius: 3,
          border: 0,
          color: 'rgb(30, 9, 4, 1)',
          fontFamily: 'Lato', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          '&:hover': {
            background:  'rgb(36, 216, 136, .75)',
          }
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);

      const StyledButtonReset = withStyles({
        root: {
          background: '#cc0000',
          borderRadius: 3,
          border: 0,
          color: '#ffffff',
          fontFamily: 'Lato', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          '&:hover': {
            background:  'rgb(36, 216, 136, .75)',
          }
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);

    let userEmail = '';
    let userPW = '';
    console.log("initial customer: " + JSON.stringify(customer));
    function checkValidEmail(email) {
        const re = /\S+@\S+\.\S+/;
        const testRes = re.test(email);
        if(testRes === false ) {
            const msg = "Invalid email format"
           // console.log(msg);
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
        return re.test(email);
    }

    function setMainPage() {
        window.location.reload(false);
    }
    
    function setUsernamef(e) {
        userEmail = e.target.value;
        //console.log("userEmail=" + userEmail);
    }

    function setPwInputf(e) {
        userPW = e.target.value;
        //console.log("userPW=" + userPW);
    }
   
    function setUsernamef2(e) {
        usernameInputReset = e.target.value;
        //console.log("usernameInputReset=" + usernameInputReset);
    }
    
    function reverseString(str) {
        return str.split("").reverse().join("");
    }

    function encodeStr (aStr) {
        const enc = Buffer.from(aStr).toString('base64')
        const encoded = reverseString(enc);
       // console.log("before: " + aStr + ", encoded = " + encoded);
        return encoded;
    }

    function readableDateFromISO(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        let month = date.getMonth()+1;
        let dt = date.getDate();
    
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
    
        const retVal = month + '-' + dt + '-' + year
        //console.log("readableDateFromISO: " + retVal);
        return retVal;
    }
    
/*
    async function updateDBstatus(status) {
       // console.log("updateDBStatus to " + status);
        const url = baseURL + 'setAcctStatus';
        
        const data = {
            email: acctEmail,
            password: encodeStr(acctDetails.password),
            status: status,
        }
        //console.log("updateDBStatus for " + JSON.stringify(data));
        const resp = await axios.post(url, data);
        //console.log(resp.data);

        let newDetails = JSON.parse(JSON.stringify(acctDetails));
        newDetails.staus = status;
        //console.log('changing status')
        setAcctDetails(newDetails);
    }
*/
    async function updateDBDeactivateSub(goodUntil) {
        console.log("updateDBDeactivateSub");
        const url = baseURL + 'deactiveSubscr';
        
        const data = {
            email: acctEmail,
            password: encodeStr(acctDetails.password),
            goodUntil: goodUntil
        }
       // console.log("updateDBDeactivateSub for " + JSON.stringify(data));
        const resp = await axios.post(url, data);
        //console.log(resp.data);

        let newDetails;
        newDetails = JSON.parse(JSON.stringify(acctDetails));
        newDetails.status = "INACTIVE";
       // console.log('changing status: ' + JSON.stringify(newDetails));

        setAcctDetails(newDetails);
    }

    async function handleLogIn() {
        inputUser.current.focus();
        console.log("email box = " + inputUser.current.value)
        inputUser.current.value = "";
        const url = baseURL + "getAccountDetails";
        const data = {
            email: userEmail,
            password: encodeStr(userPW),
        }
       // console.log("sending: " + JSON.stringify(data));
        const resp = await axios.post(url, data);
       // console.log(resp.data);
        if(resp.data === "FAIL") {
            const msg = "Login failed, please check email and password and try again.";
            setSnackBarMsg(msg);
            setSnackBarOpen(true);

            return;
        }
        
        setAcctDetails(resp.data);
        if(resp.data.label.indexOf('chargefailed') !== -1) {
            setChrgFail(true);
            const faild = resp.data.label.split(":");
            setFailDate(faild[1]);
        }

        if(resp.data.label.indexOf('canceled') !== -1) {
            const faild = resp.data.label.split(":");
            setFailDate(faild[1]);
        }

       // console.log('setting email to : ' + userEmail);
        setAcctEmail(userEmail);
        setLoggedIn(true);
    }

    async function handleCancelSubscriptionClick() {
        let expires;
        console.log("clicked Cancel Subscription button");
        const url = baseURL + "stripe/cancel-subscription";
        const data = {
            subscriptionId: acctDetails.pproc_subsc_id,
        }
/*
        // ----- temp change for local debug, pretend to stop subscription to ensure visuals are correct
        const ex1 = new Date();
        ex1.setDate(ex1.getDate() + 7);
        expires = readableDateFromISO(ex1);
        const msg = "Subscription canceled, no new charges will occur."
        setGGoodUntil(expires);
        setGCanceledState(true);
        setSnackBarMsg(msg);
        setSnackBarOpen(true);
        updateDBDeactivateSub(ex1);
        //--- end of temp changes
        return;
*/

        const resp = await axios.post(url, data);
        if( typeof resp.data !== 'undefined' && typeof resp.data.status !== 'undefined') {
            console.log("___+" + JSON.stringify(resp.data));
            if (resp.data.status === 'canceled') {
                if(typeof resp.data.current_period_end !== 'undefined') {
                    // the date returned is seconds since epoch, multiply by 1000 to get ms
                    const expires1 = new Date(resp.data.current_period_end * 1000).toISOString();
                    expires = readableDateFromISO(expires1);
                    const msg = "Subscription canceled, no new charges will occur."
                    setGGoodUntil(expires);
                    setGCanceledState(true);
                    const newDetails1 = JSON.parse(JSON.stringify(acctDetails));
                    newDetails1.goodUntil = expires1;
                    console.log("newdetails good until: " + newDetails1.goodUntil);
                    setAcctDetails(newDetails1);
                    console.log("newdetails: " + JSON.stringify(acctDetails));
                    setSnackBarMsg(msg);
                    setSnackBarOpen(true);
                    setGHideButton(true);
                    updateDBDeactivateSub(expires1);
                }
            }
        }
        console.log(resp.data);
    }

    async function handleRestartSubscriptionClick() {

        customer.email = acctEmail;
        customer.password = acctDetails.password;
        customer.licenseID = acctDetails.license_id;
        customer.id = acctDetails.pproc_acct_id;
        let trialEnd;
        let msg;

        // first see if we are still in the "good-until" range,if so , just change one thing in stripe,
        // else, they'll need to redo the credit card thing
        const nowT = new Date().getTime();
        const end = new Date(acctDetails.canceled_good_until);
        const endT = end.getTime();
       
        if(nowT <= endT) {
            //console.log("INACTIVE subscription but still in goodUntil time range, just tell stripe to not cancel at end");
            console.log("INACTIVE subscription but still in goodUntil time range, restart with a trial end date = goodUntil date");
/*
            const url = baseURL + "stripe/uncancel-subscription";
            const data = {
                subID: acctDetails.pproc_subsc_id,
            }
            console.log("calling stripe/uncancel-subscription")
            const resp = await axios.post(url, data);
*/                
            const trialEnd1 = endT / 1000;
            trialEnd =  Math.trunc(trialEnd1);
            
            console.log("trialEnd: " + trialEnd);
            msg = "Since you still had time on your old subscription, no charge will occur on the new subscription until: " + 
                readableDateFromISO(acctDetails.canceled_good_until);                
        } else {
            const date = new Date();
            date.setDate(date.getDate() + numTrialDays);
            const trialEnd1 = date.getTime() / 1000;
            trialEnd =  Math.trunc(trialEnd1);
            
            console.log("trial end = " + date.toISOString() + ", " + trialEnd);
            msg = "";
            console.log("INACTIVE subscription, beyond goodUntil date, need to start whole new subscription via credit card");
        }


        customer.trialEnd = trialEnd;
        customer.restarting = true;
        console.log(JSON.stringify(customer));
        setGCustomer(customer);
        setGProduct(stripeProduct);
        setGCanceledState(false);      // remove cancel message if it was there
        setGRestartingSub(true);
        setGHideButton(true);
        console.log("restarting subscription: " + JSON.stringify(customer));

        if(msg !== "") {
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
    }

    async function handleResetPW() {
        if( checkValidEmail(usernameInputReset) === false ){
            const msg = "Invalid email format, try again";
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        } else {
            const url = baseURL + "resetPwRequest";
           // console.log("url = " + url)
            const data = {
                email: usernameInputReset,
            };
            await axios.post(url, data);
            const msg = "Check your email for reset password instructions";
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
    }
    
    async function resetPassword() {
        setResetPW(true);
        //alert("test");
    }

    async function checkEmailExists(email) {
        const turl = baseURL;
        //const turl = "http://localhost:5000/";
        let url = turl + "hasAcct";
        const data = {email: email};
        console.log("calling hasAcct using " + url + ", data = " + JSON.stringify(data));
        const resp = await axios.post(url, data);
        console.log("check mail exists, got: " + JSON.stringify(resp.data));
        return resp.data.emailExists;
    }

    async function resendRegEmail() {
        if(typeof userEmail != 'undefined' &&  userEmail !== "") {

            const emailExists = await checkEmailExists(userEmail);
            console.log("emailExist = " + emailExists);
            if(emailExists === false) {
                setSnackBarMsg("Email: " + userEmail + ", not found, try again");
                setSnackBarOpen(true);
                return;
            }

            const url = baseURL + "adminResendRegEmail";
            //const url = "http://localhost:5000/adminResendRegEmail";
            const data = {
                email: userEmail,
            };
            await axios.post(url, data);
            const msg = "Check your email for registration details. If you need further help, email us at skysled.help@gmail.com";
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
    }

    function voidStep(stepnum) {
        if(stepnum === 3) {         //payment form send 3 to us when subscription works
            console.log("Resubscribed")
            // hide the info to make it easy
            setGHideDetails(true);
        }

    }
    const handleSnackBarClose = () => {
        /* istanbul ignore next */
        setSnackBarOpen(false);
    }
    
    function getGoodUntil() {
        if (acctDetails.canceled_good_until === "" || acctDetails.canceled_good_until == null) {
            return gGoodUntil;
        } else {
            return acctDetails.canceled_good_until;
        }
    }
  //  console.log('Account page');
  /*
                    <Button variant='contained' color='primary' onClick={handleCancelSubscriptionClick}>
                        Cancel Subscription
                    </Button>
                    <Button variant='contained' color='primary' onClick={handleRestartSubscriptionClick}>
                        Restart Subscription
                    </Button>

                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>handleLogIn()}>
                        Log In
                </Button>
  */
    return(
    <div>
            <AppBar position="static"  style={{ background: 'transparent', boxShadow: 'none'}}>
                <StyledButtonTop><LinkRouter style={{ color: "#24D888",textDecoration: 'none' }} to="/">MY AD-FREE PLAYER</LinkRouter></StyledButtonTop>
            </AppBar>

        <div className='Container-centered'>

            { loggedIn===true ?
                <div>
                {gHideDetails === false ?    
                        <TableContainer>
                            <Table>
                                <TableHead></TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><Typography>Account Email:</Typography></TableCell>
                                        <TableCell><Typography>{acctEmail}</Typography></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Typography>Account Creation:</Typography></TableCell>
                                        <TableCell><Typography>{readableDateFromISO(acctDetails.creation_date)}</Typography></TableCell>
                                    </TableRow>                            
                                    <TableRow>
                                        <TableCell><Typography>Subscription Status:</Typography></TableCell>
                                        <TableCell><Typography>{acctDetails.status}</Typography></TableCell>
                                    </TableRow>

                                    {acctDetails.status === 'INACTIVE' ?
                                        (chrgFail === false ?
                                        <TableRow>
                                            <TableCell><Typography>Cancelled Subscription on: {failDate}:</Typography></TableCell>
                                            <TableCell><Typography>Remaining Time Ends: {readableDateFromISO(getGoodUntil())}</Typography></TableCell>                                               
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell><Typography>Inactive Reason:</Typography></TableCell>
                                            <TableCell><Typography>Monthly Charge Failed: ({failDate})</Typography></TableCell>
                                        </TableRow>
                                        )                                       
                                     :   
                                    <br />
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                :
                <br />    
                }
                
                <br />
                {gHideButton === false ?
                   (acctDetails.status === "ACTIVE" ?

                    <StyledButtonCont onClick={handleCancelSubscriptionClick}>
                        Cancel Subscription
                    </StyledButtonCont>
                    :

                    <StyledButtonCont onClick={handleRestartSubscriptionClick}>
                        Restart Subscription
                    </StyledButtonCont>
                   )
                :    
                <br />
                }
                
                    {gRestartingSub===true ?
                    <div>
                        <Typography>Restarting Subscription</Typography>
                        <br /><br />
                        <PaymentForm
                            productSelected={gProduct}
                            customer={gCustomer}
                            setNextStep={voidStep}
                        />

                    </div>
                    :
                    <br />
                    }

                </div>
            :
                (resetPW === false? 
                <div>
                <Typography className="sub-Title4">Log In:</Typography><br /><br />
                <TableContainer>
                    <Table>
                        <TableHead></TableHead>
                        <TableBody>
                        <TableRow>
                            <TableCell className="table-cell-left">Username (email):</TableCell>
                            <TableCell>
                                <TextField 
                                    autoComplete="off"
                                    id="txt_username" 
                                    label="Enter user email..." 
                                    variant="outlined"
                                    onChange={(e)=>setUsernamef(e)}
                                    ref={inputUser}
                                /> 
                                
                                </TableCell> 
                        </TableRow>
                        <TableRow>
                            <TableCell className="table-cell-left">Password:</TableCell>
                            <TableCell>
                                <TextField 
                                  autoComplete="off"
                                  id="txt_password" 
                                  label="Enter Password..." 
                                  type="password"
                                  variant="outlined"
                                  onChange={(e) => setPwInputf(e)}
                                  />
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className="info-box2">
                <StyledButtonCont
                        onClick={()=>handleLogIn()}>Log in
                </StyledButtonCont>
                </div>
                
                <div>
                &nbsp;
                <Tooltip title="Forgot password? Click here to reset your password. You will need at least the email address.  If not, contact us at skysled.help@gmail.com" placement="top">
                <Link onClick={()=>resetPassword()}>
                    Forgot Password                    
                </Link>
                </Tooltip>
                <br />
                <Tooltip title="Lost your registration email and need the info to re-register your player? You will need your original email.  If not, contact us at skysled.help@gmail.com" placement="top">
                <Link onClick={()=>resendRegEmail()}>
                    Resend Registration Email                    
                </Link>
                </Tooltip>
                </div>

                </div>
                : 
                <div>
                    <Typography color="primary"><h3>Reset Password</h3></Typography>
                    <br /><br />
                    <TableContainer>
                    <Table>
                        <TableHead></TableHead>
                        <TableBody>
                        <TableRow>
                            <TableCell>Username:</TableCell>
                            <TableCell>
                                <TextField 
                                    id="txt_username2" 
                                    label="Email..." 
                                    variant="outlined"
                                    onChange={(e)=>setUsernamef2(e)}
                                /> 
                                
                                </TableCell> 
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>setResetPW(false)}>
                        Back
                    
                </Button>
                &nbsp;
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>handleResetPW()}>
                    Send Reset Password Link
                </Button>    

                &nbsp;
                </div>
                )
            }

        </div>
            { gCanceledState === true?
                <Typography>Subscription Succesfully canceled.  Account is paid up to until {gGoodUntil}<br /><br />
                You can restart your subscription in the future from this account page.</Typography>
            :
                <br />
            }
            <Snackbar open={snackBarOpen} onClose={handleSnackBarClose} message={snackBarMsg} autoHideDuration={5000} />
        </div>
    );
};



const SignIn = (props) => (
    <Account {...props} />
);

export default SignIn;