import React from "react"
import { Route, Switch } from "react-router-dom"
import Home from "./components/Home"
import Download from "./components/Download"
import SignIn from "./components/SignIn"
import Error from "./components/Error"
import NavBar from "./components/NavBar"
import Purchase from "./components/Purchase"
import ResetPW from "./components/ResetPW"

function App() {
    return (
        <main>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/download" component={Download} />
                <Route path="/purchase" component={Purchase} />
                <Route path="/signin" component={SignIn} />
                <Route path='/resetpw' component={ResetPW} />
                <Route component={Error} />
            </Switch>
        </main>
    )
}

export default App; 