import React, { Fragment, useState } from "react"
import {Link} from "react-router-dom";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button'
import ResetPW from './ResetPW';
import {Route} from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";

import 'fontsource-roboto';
import '../App.css';

const accordianStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E092C',
    width: '100%',
    
  },
  heading: {
    backgroundColor: '#3E1358',
    color: '#DADADA',
    fontFamily: 'Lato',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.05em',
  },
  body: {
    color: "#DADADA",
    fontFamily: 'Lato',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    letterSpacing: '0.05em',
  },

}));


const accordianStylesMobile = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E092C',
    width: '100%',
    
  },
  heading: {
    backgroundColor: '#3E1358',
    color: '#DADADA',
    fontFamily: 'Lato',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.05em',
  },
  body: {
    color: "#DADADA",
    fontFamily: 'Lato',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    letterSpacing: '0.05em',
  },

}));



function Home() {
  const accordianClasses = isMobile === true ? accordianStylesMobile() : accordianStyles();
  const [resetPW, setResetPW] = useState(false);

  let resetToken = (new URLSearchParams(window.location.search)).get("resetPassword")
  console.log("resetToken " + resetToken);
  
  if(resetToken != null && resetToken !== "") {
    if(resetPW === false) {
      setResetPW(true);
    }
  }

  const StyledButtonBig = withStyles({
    root: {
      background: '#24D888',
      borderRadius: 3,
      border: 0,
      color: '#1E092C',
      fontFamily: 'Oswald', 
      fontWeight: 400,
      fontSize: 32,
      height: 62,
      padding: '0 50px',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
      '&:hover': {
        background: 'rgb(7, 177, 77, 0.75)',
      }
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);
  const StyledButtonBigMobile = withStyles({
    root: {
      background: '#24D888',
      borderRadius: 3,
      border: 0,
      color: '#1E092C',
      fontFamily: 'Oswald', 
      fontWeight: 400,
      fontSize: 18,
      height: 62,
      padding: '0 50px',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
      '&:hover': {
        background: 'rgb(7, 177, 77, 0.75)',
      }
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

  const StyledButtonSm = withStyles({
    root: {
      background: 'rgb(24, 216, 136, 0.00)',
      borderRadius: 3,
      border: 0,
      color: '#24D888',
      fontFamily: 'Lato', 
      fontWeight: 'bold', 
      fontSize: 24,
      height: 48,
      padding: '0 20px',
      textShadow: '2px 2px 0px rgba(0,0,0,0.8)',
      '&:hover': {
        background: 'rgb(7, 177, 77, 0.00)',
      }
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

  // textShadow: '2px 2px 0px rgba(0,0,0,0.8)',
  return (
    <div>
      {resetPW === true ?
        <div className="App">
              <ResetPW 
              changePage={null} 
              code={resetToken} />
        </div>
      :
    <div className="App">
      <header className="App-header-new">
      <StyledButtonSm className="Downloads-Button"><Link style={{ color: "#24D888",textDecoration: 'none' }} to="/download">Downloads</Link></StyledButtonSm>
      <StyledButtonSm className="Account-Button"> <Link style={{ color: "#24D888",textDecoration: 'none' }} to="/signin">Sign In</Link></StyledButtonSm>
        {isMobile === true ? 

          <Fragment>
            <Typography className="Mobile-subTitle1">Watch your favorite shows, ad-free.</Typography>
            <Typography className="Mobile-Title">MY AD-FREE PLAYER</Typography>
          </Fragment>     
        :
          <Fragment>
            <Typography className="sub-Title1">Watch your favorite shows, ad-free.</Typography>
            <Typography className="Title">MY AD-FREE PLAYER</Typography>
          </Fragment>     
        }

        <Typography>
              Hate ads? Save money with my<br />
        ad-free player, and for $5 per month, <br />
        watch your shows ad-free.
        </Typography>
        <br />
        {isMobile === true ?
          <StyledButtonBigMobile><Link style={{ color: "#1E092C",textDecoration: 'none' }} to="/purchase">Start your free trial today!</Link></StyledButtonBigMobile>
        :
          <StyledButtonBig ><Link style={{ color: "#1E092C",textDecoration: 'none' }} to="/purchase">Start your free trial today!</Link></StyledButtonBig>
        }
      </header>
      <div style={{backgroundColor: '#3E1358'}}>&nbsp;</div>
      
      <div className="App-Body">
      <br />
        <Typography className="FAQText">FREQUENTLY ASKED QUESTIONS</Typography>
        <br />
        <div className="App-Body-FAQTable">

        <div className={accordianClasses.root}>
          <Accordion  style={{backgroundColor:'#3E1358', marginBottom: '5px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color:"#F1F1F1" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={accordianClasses.heading}>What is My Ad-Free Player?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="Container-flex">
              <Typography className={accordianClasses.body}>
              My Ad-Free Player is a desktop application that plays videos and tv shows without ads.
                    Right-click a URL, copy to your clipboard, and My Ad-Free Player begins playing the
                    content you want to see, ad-free. My Ad-Free Player can even queue up an entire season
                    or series. Need subtitles? No problem, My Ad-Free Player plays shows with subtitles as
                    well.<br /><br />Want a walkthrough? Check out My Ad-Free Player on our video channels,
                    <a href="https://www.dailymotion.com/dm_e1d50d4b2068cca9bc626d261ba3ca3c" target="_new"> here</a> and 
                    <a href="https://www.youtube.com/channel/UCsE8pZH0Nco1hKeV51GuB2w" target="_new"> here </a>. 
              </Typography>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{backgroundColor:'#3E1358', marginBottom: '5px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color:"#F1F1F1" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={accordianClasses.heading}>How much is My Ad-Free Player?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={accordianClasses.body}>
               My Ad-Free Player costs a monthly fee of $5 and includes compatibility with several sites 
               (see the list in the "What can I watch with My Ad-Free player?" FAQ section below). 
               Together these services offer an ad-free experience
               costing over $16 per month. My Ad-Free Player saves you $11 each month, with more
               websites and savings to come.<br /><br />
               With your support of My Ad-Free Player, we can increase
               functionality for even more sites and keep current websites up to date. We can help you
               save more money each month and free you from unwanted ads.<br /><br />
               Want compatibility with more
               platforms? We do too. With your support, we can begin development and further expand
               My Ad-Free Player functionality. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{backgroundColor:'#3E1358', marginBottom: '5px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color:"#F1F1F1" }} />}
              aria-controls="panel2a-content"
              id="panel3a-header"
            >
              <Typography className={accordianClasses.heading}>How can I watch my shows ad-free?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={accordianClasses.body}>
               Watching your shows on My Ad-Free Player is easy. <br />
                <div style={{margin:'5%'}}>
                 1. Open the My Ad-Free Player program.<br /><br />
                 2. Copy the web address for the video you wish to watch.<br /><br />
                 3. Your video will begin to play through My Ad-Free Player.<br /><br />
                 </div>
                 Want to view your shows on your television? Send content to your television through an HDMI cable or a wireless streaming device.* 
                 <br/><br />
                 My Ad-Free Player is currently compatible with Windows 10.<br /><br />
                 *My Ad-Free Player does not offer customer support for use with third-party devices.                 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{backgroundColor:'#3E1358', marginBottom: '5px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color:"#F1F1F1" }} />}
              aria-controls="panel2a-content"
              id="panel4a-header"
            >
              <Typography className={accordianClasses.heading}>What can I watch with My Ad-Free player?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={accordianClasses.body}>
              My Ad-Free Player is currently compatible with the following Sites:<br/>
              <li>YouTube</li> <li>Facebook</li> <li>TubiTV</li> <li>Twitch</li> <li>Funimation</li>
              <li>ShoutFactoryTV</li> <li>Dailymotion</li> <li>Rumble</li> <li>IMDB</li> <li>OnDemandKorea</li> <li>AsianCrush</li>
              with more websites coming soon.<br /><br />
              My Ad-Free Player is not compatible with content locked behind paywalls or encrypted.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{backgroundColor:'#3E1358', marginBottom: '5px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color:"#F1F1F1" }} />}
              aria-controls="panel2a-content"
              id="panel5a-header"
            >
              <Typography className={accordianClasses.heading}>How can I cancel?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={accordianClasses.body}>
              Need to take a break from My Ad-Free Player? No problem, go to your account page
              on our website and click cancel subscription: no commitments, no contracts, and no fees.
              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
        <br />
            <br />
            {isMobile === true ?
              <StyledButtonBigMobile ><Link style={{ color: "#1E092C",textDecoration: 'none' }} to="/purchase">Are you ready for ad-freedom?</Link></StyledButtonBigMobile>     
              :
              <StyledButtonBig><Link style={{ color: "#1E092C",textDecoration: 'none' }} to="/purchase">Are you ready for ad-freedom?</Link></StyledButtonBig>     
            }
        </div>
      </div>
    
    </div>
    }
    </div>
  )}

export default Home