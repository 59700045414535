import React, { useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
//import logoBTC from './logos/logo_btc.jpg';
//import logoPatreon from './logos/logo_patreon.jpg';
import logoStripe from './logos/logo_stripe.jpg';

import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
//import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import PaymentForm from './PaymentForm';

import 'fontsource-roboto';
import '../App.css';

const DEBUG = 0;
let baseURL;
let numTrialDays;

if(DEBUG===1) {
    baseURL = 'http://localhost:5000/' 
    numTrialDays = 1;
} else {
    baseURL = 'https://myadfreeplayer.xyz/';
    numTrialDays = 7;
}
console.log("baseURL = " + baseURL);

let usernameInput = '';
let pwInput = '';
let pwInputConf = '';
//let custLicID = '';
let gCustID;

function setPwInputf(e) {
    pwInput = e.target.value;
    //console.log("pw = " + pwInput);
}

function reverseString(str) {
    return str.split("").reverse().join("");
}

function setPwConfInputf(e) {
    pwInputConf = e.target.value;
    //console.log("pw = " + pwInputConf);
}

function encodeStr(aStr) {
    const encoded = Buffer.from(aStr).toString('base64')
    //console.log("before: " + aStr + ", encoded = " + encoded);
    const revEnc = reverseString(encoded);
    return revEnc;
}

export const PurchaseForm = ({ changePage }) => {
    const [badEmail, setBadEmail] = useState(false);
    const [badPw, setBadPW] = useState(false);
    const [badConfPW, setBadConfPW] = useState(false);
    const [step, setStep] = useState(0);
    const [pmtMethod, setPmtMethod] = useState(0);
    //const [username, setUsername] = useState("");
    //const [pw, setPw] = useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [gCustomer, setGCustomer] = useState({id:0,email:'',password:'',licenseID:''});
    const [gProduct, setGProduct] = useState({key:0,price:"$5.00",name:"My Ad-Free Player License",interval:"month",billed:"monthly"});
    const tnow = (new Date().getTime) / 1000;
    //const disableNonStripe = true;

    const stripeProduct = {
        key: 0,
        price: '$5.00',
        amount: 500, 
        name: 'My Ad-Free Player License',
        interval: 'month',
        billed: 'monthly',
    };

    let customer = {
        id: 0,
        email: '',
        password: '',
        licenseID: '',
        trialEnd: tnow,
        restarting: false,
    };

    const StyledButtonTop = withStyles({
        root: {
          background: 'rgb(25, 25, 25, 0)',
          borderRadius: 3,
          border: 0,
          color: '#24D888',
          fontFamily: 'Oswald', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          position: 'absolute',
          top: '10px !important',
          left: '10px !important',
          textShadow: '1px 1px 0px rgba(0,0,0,0.8)',
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);

      const StyledButtonCont = withStyles({
        root: {
          background: '#24D888',
          borderRadius: 3,
          border: 0,
          color: 'rgb(30, 9, 4, 1)',
          fontFamily: 'Lato', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          '&:hover': {
            background:  'rgb(36, 216, 136, .75)',
          }
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);
    function setUsernamef(e) {
        usernameInput = e.target.value;
        if(badEmail === true) {
            setBadEmail(false);
        }
        //console.log("username = " + usernameInput);
    }

    function setMainPage() {
        window.location.reload(false);
    }

    async function handleNext() {
        let bErrors = false;
        if (checkValidEmail(usernameInput) === false) {
            bErrors = true
            //return;
        }
        if(checkValidPW(pwInput) === false) {
            setBadPW(true)
            bErrors = true
            //return;
        }
        if(checkValidPW(pwInputConf) === false) {
            bErrors = true
            setBadConfPW(true)
            //return;
        }

        if( pwInput !== pwInputConf) {
            console.log("Password and Confirm Password didn't match!")
            let msg = "Passwords did not match, try again"
            setSnackBarMsg(msg)
            setSnackBarOpen(true)
            return;
        }
        if(bErrors === true) {
            return;
        }
        //await getLicenseID(usernameInput, pwInput);
        // first, check to see if the email is already in the database and warn the user if it is found
        const alreadyExists = await checkEmailExists(usernameInput);
        if(alreadyExists === true) {
            setBadEmail(true);
            setSnackBarMsg("Email already registered");
            setSnackBarOpen(true);
            return;
        }

        console.log("calling createInitialAccount");
        const encodedPW = encodeStr(pwInput);
        const respData = await createInitialAccount(usernameInput, encodedPW /*pwInput*/); //- off for now to not pollute DB with all my testing
        //let respData = {};
        //respData.results = "SUCCESS";
        //respData.licenseID = "413f35f899e578d45043dd55cdaa3d1b";

        //update the customer structure
        if( respData.results !== "FAIL" ) {
            customer.email = usernameInput;
            customer.password = pwInput;
            customer.licenseID = respData.licenseID;
                const date = new Date();
                date.setDate(date.getDate() + numTrialDays);
                const trialEnd1 = (date.getTime()) / 1000;
                const trialEnd =  Math.trunc(trialEnd1);
                console.log("trial end = " + date.toISOString() + ", " + trialEnd);
            customer.trialEnd = trialEnd;
            console.log("username = " + customer.email + ", password = " + customer.password + ", lic = " + customer.licenseID );

            //setUsername(usernameInput);
           // setPw(pwInput);
           setGCustomer(customer);
           setGProduct(stripeProduct);

           console.log("gCustomer: " + JSON.stringify(gCustomer));
           console.log("gProduct: " + JSON.stringify(gProduct));
           const msg = "Account created for ID: " + usernameInput; // + ", pw = " + pwInput + ", licenseID = " + custLicID;
           //const msg = "Account created: " + JSON.stringify(gCustomer);
           setSnackBarMsg(msg);
           setSnackBarOpen(true);
          // setStep(1);
            handleSelectStripe();
        } else {
           const msg1 = "Creating User account failed, try again later";
           console.log("Error creating user: " + respData.msg);
           setSnackBarMsg(msg1);
           setSnackBarOpen(true);
        }

    }

    const handleSnackBarClose = () => {
        /* istanbul ignore next */
        setSnackBarOpen(false);
    }

    function checkValidEmail(email) {
        const re = /\S+@\S+\.\S+/;
        const testRes = re.test(email);
        if(testRes === false ) {
            const msg = "Invalid email format"
            console.log(msg);
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
        return re.test(email);
    }
    
    function checkValidPW(str) {
        let msg = '';
        let results = false;
    
        if (str.length < 6) {
            msg = "Password is too short (6 chars minimum)";   
    
        } else if (str.length > 50) {
            msg = "Password is too long (50 chars maximum)";
    
        } else if (str.search(/\d/) === -1) {
            msg = "No Number found (must include a number)";
            
        } else if (str.search(/[a-zA-Z]/) === -1) {
            msg = "No Letters found (must include some letters)";
    /*       
        } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
            msg = "No special characters found(at least one)";
    */       
        }
        else {
            results = true;
        } 
       
        if(results === false) {        
            console.log(msg);
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
    
        return results;
    }
/*
    function handleSelectBitcoin() {
        console.log("handleSelectBitcoin");
        //temporarily disable bitcoin until I get that finished
        if(disableNonStripe === true) {
            const msg = "Bitcoin subscription method coming soon, please use Credit Card for now."
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        } else {
            setPmtMethod(1);
            setStep(2);
        }
    }
*/
    function handleSelectStripe() {
        console.log("handleSelectStripe"); 
    //    setPmtMethod(2);
    //    setStep(2);
    //    return;
        // create a stripe account

        console.log("cust and prod: " + JSON.stringify(customer.email) + " .... " + JSON.stringify(gProduct));

        console.log("calling /stripe/create-customer")
        //return
        const url = baseURL + 'stripe/create-customer';
        axios.post(url, {email: customer.email,})
        .then( resp => {
            console.log('stripe create customer: ' + JSON.stringify(resp.data));
            gCustomer.id = resp.data;
            gCustID = resp.data;
            console.log("stripe customer id = " + gCustomer.id);
            setPmtMethod(2);
            setStep(2);
        });
    }
/*
    function handleSelectPatreon() {
        console.log("handleSelectPatreon");
          //temporarily disable bitcoin until I get that finished
          if(disableNonStripe === true) {
            const msg = "Patreon subscription method coming soon, please use Credit Card for now."
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        } else {
            setPmtMethod(3);
            setStep(2);
        }    
    }
*/
    async function checkEmailExists(email) {
        const turl = baseURL;
        let url = turl + "hasAcct";
        const data = {email: email};
        console.log("calling hasAcct using " + url + ", data = " + JSON.stringify(data));
        const resp = await axios.post(url, data);
        console.log("check mail exists, got: " + JSON.stringify(resp.data));
        return resp.data.emailExists;
    }

    async function createInitialAccount(email, pw) {
        let url = baseURL + "createAccount";
        console.log("createInitialAccount, url = " + url)
        const data = {
            email: email,
            password: pw,
        }
        const resp = await axios.post(url, data);
        console.log("createInitialAccount results from server: " + JSON.stringify(resp.data));
        return resp.data;
    }
/*
    async function getLicenseID(email, pw) {
        let url = baseURL + "genLicID";
        const data = {
            email: email,
            password: pw,
        }
        const resp = await axios.post(url, data);
        console.log("licenseID generated by server: " + resp.data);
        custLicID = resp.data;
    }

                <div className="Header-area">
                <Typography className="color-green"><u><b>Purchase Page</b></u></Typography>
            </div> 
            <Typography><b>Ready to start watching ad-free videos?</b></Typography>
                               <Typography>
                    You will first need to register this program and start a subscription for service.<br />
                    <br />
                    Here are the facts you need to know: <br />
                    <li>Registering an account starts a subscription that costs only $5 USD per month</li>
                    <li>Don't like this program? Miss your ads? Simple, just unsubscribe anytime</li>
                    <li>Up to 4 devices can be linked to an active account</li>
                    <li>Payment is via Credit Card now and soon Bitcoin</li>
                    </Typography>
                    
                                    <Toolbar>
                    <Typography variant="h6" noWrap>
                    </Typography>
                </Toolbar>
                                    <h3>Subscription Method:
                    { pmtMethod===1 ? ' Bitcoin' : (pmtMethod === 2 ? ' Stripe' : ' Patreon')}
                    </h3>

                    */
    return (
        <div className="standard-page">
            <AppBar position="static"  style={{ background: 'transparent', boxShadow: 'none'}}>
                <StyledButtonTop><Link style={{ color: "#24D888",textDecoration: 'none' }} to="/">MY AD-FREE PLAYER</Link></StyledButtonTop>
            </AppBar>

            <div className="Container-centered">
                <br /><br />
                { (step===0) ?
                <div>
                    <div className="info-box">
                        <Typography className="sub-Title5">Enjoy your first week free!</Typography>
                    </div>
                    <br />
                    <Typography className="sub-Title3">Step 1 of 2</Typography>
                    <Typography className="sub-Title4">Create Your Account</Typography>
                    <br />

                    <TableContainer>
                        <Table>
                            <TableHead></TableHead>
                            <TableBody>
                            <TableRow>
                                <TableCell className="table-cell-left"><Typography className="instruction-text">Username (email):</Typography></TableCell>
                                <TableCell>
                                    
                                    <TextField 
                                        autoComplete="off"
                                        id="txt_username" 
                                        //label="Enter email..." 
                                        variant="outlined"
                                        onChange={(e)=>setUsernamef(e)}
                                    /> 
                                    {badEmail===true ?
                                        <Typography color='secondary'>Choose Another Email</Typography>
                                    :
                                        <br />
                                    }
                                    </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="table-cell-left">Password:</TableCell>
                                <TableCell>
                                <Tooltip title="between 6 and 50 characters, at least 1 letter and 1 number">
                                    <TextField 
                                      autoComplete="off"  
                                      className="color-off-white"
                                      id="txt_password" 
                                      error={badPw}
                                      helperText={badPw===true ? "Error" : ""}
                                      //label="Enter Password..." 
                                      type="password"
                                      variant="outlined"
                                      onChange={(e) => setPwInputf(e)}
                                      /></Tooltip>
                                        &nbsp; 
                                      
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="table-cell-left">Confirm Password:</TableCell>
                                <TableCell>
                                <Tooltip title="Confirm password">
                                    <TextField 
                                      autoComplete="off"  
                                      className="color-off-white" 
                                      id="txt_conf_password" 
                                      error={badConfPW}
                                      helperText={badConfPW===true ? "Error" : ""}
                                      //label="Confirm Password..." 
                                      type="password"
                                      variant="outlined"
                                      onChange={(e) => setPwConfInputf(e)}
                                      /></Tooltip>
                                       &nbsp;
                                </TableCell>
                            </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="info-box2">
                        <StyledButtonCont
                            onClick={()=>handleNext()}>Continue
                        </StyledButtonCont>
                    </div>
                    <br /> <br />
                    By signing up for this service, you agree to our <a href="privpolicy.html">Privacy Policy</a> and
                    our <a href="terms.html">Terms of Service.</a>
                </div>
                : 
                (step===1) ?
                <div>

                <div className="info-box3">
                    <Typography className="instruction-text">You won't be charged until after your free month.<br />No commitments, cancel anytime.</Typography>
                </div>
                <br />
                <Typography className="sub-Title3">Step 2 of 3</Typography>
                <Typography className="sub-Title4">Select Payment Form</Typography>
                <br />

                <br />

                    <TableContainer>
                    <Table className="PmtTable" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Button onClick={handleSelectStripe}>
                                    <img src={logoStripe} width="200px" alt="Stripe" />
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleSelectStripe}>
                                    Subscribe with major cards with Stripe
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography>
                                    <br />
                                    <strong>Registering with BitCoin is coming soon</strong>
                                    <br />
                                </Typography>
                            </TableCell>
                        </TableRow>

                        </TableBody>
                    </Table>
                    </TableContainer>
                    <div className="info-box2">
                    <StyledButtonCont
                        onClick={()=>setStep(0)}>Back
                    </StyledButtonCont>
                    </div>
                </div>
                :
                <div>                
                <Typography className="sub-Title3">Step 2 of 2</Typography>
                <Typography className="sub-Title4">Enter Payment Details</Typography>

                    <div>
                        {pmtMethod===1 ?
                            <div>
                            <a class="buy-with-crypto"
                                href="https://commerce.coinbase.com/checkout/3b309aea-c3b2-49c6-a748-7af8e3f03034">
                                Buy with Crypto
                            </a>
                            <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
                            </script>
                            </div>
                            :
                            (pmtMethod===2 ?
                            <div>
                                <PaymentForm
                                    productSelected={gProduct}
                                    customer={gCustomer}
                                    setNextStep={setStep}
                                    gCID={gCustID}
                                />
                            </div>
                            :   
                            <div> next </div>
                            )
                        }
                    </div>
                    {step===2 ?
                    <div>
                        <br /><br />
                        <StyledButtonCont
                            onClick={()=>setStep(0)}>Back
                        </StyledButtonCont>
                    </div>
                    :
                    <br />
                    }
                </div>
                }
            </div>
            <br />
            <Snackbar open={snackBarOpen} onClose={handleSnackBarClose} message={snackBarMsg} autoHideDuration={3000} />
        </div>
    );
}

const Purchase = (props) => (
      <PurchaseForm {...props} />
);
  
export default Purchase;
/*
                        <TableRow>
                            <TableCell>
                                <Button onClick={handleSelectBitcoin}>
                                    <img src={logoBTC} width="200px" alt="Bitcoin"/>
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleSelectPatreon}>
                                    Subscribe with a monthly bitcoin invoice
                                </Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Button onClick={handleSelectPatreon}>
                                    <img src={logoPatreon} width="200px" alt="Patreon" />
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleSelectPatreon}>
                                    Become a Patreon for this project
                                </Button>
                            </TableCell>
                        </TableRow>
*/