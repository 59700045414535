import React, { useState } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'
import { makeStyles, themes, withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

import 'fontsource-roboto';
import '../App.css';

const DEBUG = 0;
let basePageURL;
let baseServerURL;
const emailHelp = "skysled.help@gmail.com";

if(DEBUG===1) {
    basePageURL = "http://localhost:3000/";
    baseServerURL = "http://localhost:5000/"
} else {
    basePageURL = "https://myadfreeplayer.com/";
    baseServerURL = "https://myadfreeplayer.xyz/";
}
const StyledButtonTop = withStyles({
  root: {
    background: 'rgb(25, 25, 25, 0)',
    borderRadius: 3,
    border: 0,
    color: '#24D888',
    fontFamily: 'Oswald', 
    fontWeight: 'bold', 
    fontSize: 24,
    height: 48,
    padding: '0 20px',
    position: 'absolute',
    top: '10px !important',
    left: '10px !important',
    textShadow: '1px 1px 0px rgba(0,0,0,0.8)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);
export const ResetPassword = (props) => {
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');    
    const [badEmail, setBadEmail] = useState(false);
    const [badPw, setBadPW] = useState(false);
    const [badConfPW, setBadConfPW] = useState(false);

    const setPage = props.changePage;
    let userEmail = '';
    let userPW = '';
    let userPW2 = '';

   // console.log("props = " + JSON.stringify(props));
    function setMainPage() {
        //console.log('calling setPage(0)');
        //setPage(0);
       console.log("setting window.location to " + basePageURL)
       window.location = basePageURL;
    }

   function checkValidEmail(email) {
        const re = /\S+@\S+\.\S+/;
        const testRes = re.test(email);
        if(testRes === false ) {
            const msg = "Invalid email format"
            console.log(msg);
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
        return re.test(email);
    }
    
    function checkValidPW(str) {
        let msg = '';
        let results = false;
    
        if (str.length < 6) {
            msg = "Password is too short (6 chars minimum)";   
    
        } else if (str.length > 50) {
            msg = "Password is too long (50 chars maximum)";
    
        } else if (str.search(/\d/) === -1) {
            msg = "No Number found (must include a number)";
            
        } else if (str.search(/[a-zA-Z]/) === -1) {
            msg = "No Letters found (must include some letters)";
    /*       
        } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) != -1) {
            msg = "No special characters found(at least one)";
    */       
        }
        else {
            results = true;
        } 
       
        if(results === false) {        
            console.log(msg);
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
    
        return results;
    }
    function reverseString(str) {
        return str.split("").reverse().join("");
    }

    function encodeStr(aStr) {
        // new method encoded pw is also reversed
        const enc = Buffer.from(aStr).toString('base64');
        const encoded = reverseString(enc);
        //console.log("before: " + aStr + ", encoded = " + encoded);
        return encoded;
    }
    
    async function doResetPassword(email, pw) {
        let url = baseServerURL + "resetPassword";
        //console.log("resetPassword, url = " + url)
        const data = {
            email: email,
            newPassword: pw,
            code: props.code,
        }
        const resp = await axios.post(url, data);
        console.log("resetPassword results from server: " + JSON.stringify(resp.data));
        return resp.data;
    }

    async function handleSubmit() {
        let msg = '';
        let bErrors = false;

        if (checkValidEmail(userEmail) === false) {
            bErrors = true
            return;
        }
        if(checkValidPW(userPW) === false) {
            setBadPW(true)
            bErrors = true
            return;
        }
        if(checkValidPW(userPW2) === false) {
            bErrors = true
            setBadConfPW(true)
            return;
        }
    
        if( userPW !== userPW2) {
            console.log("Password and Confirm Password didn't match!")
            let msg = "Passwords did not match, try again"
            setSnackBarMsg(msg)
            setSnackBarOpen(true)
            return;
        }

        if(bErrors === true) {
            const msg = "Check inputs before trying to change the Password";
            setSnackBarMsg(msg);
            setSnackBarOpen(true);
        }
        console.log("calling resetPassword on server");
       // console.log("code = " + props.code + ", email = " + userEmail + ", pw = " + userPW);
        const encodedPW = encodeStr(userPW);
       // console.log("enc PW = " + encodedPW);
        const respData = await doResetPassword(userEmail, encodedPW); 
        if (respData === "SUCCESS") {
            msg = "Password Successfully changed!";
        } else {
            if(respData === "EXPIRED") {
                msg = "Password Reset email expired, Try Reset again for new email.";
            } else {
                msg = "Password change failed. Try again or email us at: " + emailHelp;
            }
        }
        setSnackBarMsg(msg);
        setSnackBarOpen(true);
    }

    function setUsernamef(e) {
        userEmail = e.target.value;
        //console.log("userEmail=" + userEmail);
        if(badEmail === true) {
            setBadEmail(false);
        }
    }
    
    function setPwInputf(e) {
        userPW = e.target.value;
        //console.log("userPW=" + userPW);
        if(badPw === true) {
            setBadPW(false);
        }
    }
    
    function setPwInputConf(e) {
        userPW2 = e.target.value;
        //console.log("userPW2=" + userPW2);
        if(badConfPW === true) {
            setBadConfPW(false);
        }
    }
    
    const handleSnackBarClose = () => {
        /* istanbul ignore next */
        setSnackBarOpen(false);
    }
    return(
        <div>
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                    <StyledButtonTop><Link style={{ color: "#24D888",textDecoration: 'none' }} to="/">MY AD-FREE PLAYER</Link></StyledButtonTop>
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="Header-area">
                <Typography><u><b>Reset Password</b></u></Typography>
            </div> 

            <div className='Container'>
            </div>

            <TableContainer>
                <Table>
                    <TableHead></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell>Username:</TableCell>
                        <TableCell>
                            <TextField 
                                id="txt_username" 
                                error={badEmail}
                                helperText={badEmail===true ? "Error" : ""}
                                label="Enter user email..." 
                                variant="outlined"
                                onChange={(e)=>setUsernamef(e)}
                            /> 
                        </TableCell> 
                    </TableRow>
                    <TableRow>
                        <TableCell>New Password:</TableCell>
                        <TableCell>
                            <TextField 
                                id="txt_password" 
                                error={badPw}
                                helperText={badPw===true ? "Error" : ""}
                                label="Enter Password..." 
                                type="password"
                                variant="outlined"
                                onChange={(e) => setPwInputf(e)}
                                />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Confirm:</TableCell>
                        <TableCell>
                            <TextField 
                                id="txt_password2" 
                                error={badConfPW}
                                helperText={badConfPW===true ? "Error" : ""}
                                label="Confirm Password..." 
                                type="password"
                                variant="outlined"
                                onChange={(e) => setPwInputConf(e)}
                                />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button 
                variant="contained"
                color="primary"
                onClick={()=>handleSubmit()}>
                    Reset Password
            </Button>
            <Snackbar open={snackBarOpen} onClose={handleSnackBarClose} message={snackBarMsg} autoHideDuration={5000} />
        </div>
    );
}

const ResetPW = (props) => (
    <ResetPassword {...props} />
);

export default ResetPW;