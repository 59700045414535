import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import logoPDF from './logos/pdf-icon.png';
import logoAndroid from './logos/android.png';
import logoWindows from './logos/windows.png';
import logoRoku from './logos/roku.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Link, Route, Switch } from "react-router-dom"
/*
import Home from "./Home"
//import Download from "./components/Download"
import SignIn from "./SignIn"
import Error from "./Error"
//import NavBar from "./components/NavBar"
import Purchase from "./Purchase"
import ResetPW from "./ResetPW"
*/

import 'fontsource-roboto';
import '../App.css';
//import data from './mafp_dlfile.json';
//let tdata = {};


export const DownloadForm = () => {
        const [dlData, setDLData] = useState({});

        const StyledButtonTop = withStyles({
        root: {
          background: 'rgb(25, 25, 25, 0)',
          borderRadius: 3,
          border: 0,
          color: '#24D888',
          fontFamily: 'Oswald', 
          fontWeight: 'bold', 
          fontSize: 24,
          height: 48,
          padding: '0 20px',
          position: 'absolute',
          top: '10px !important',
          left: '10px !important',
          textShadow: '1px 1px 0px rgba(0,0,0,0.8)',
        },
        label: {
          textTransform: 'capitalize',
        },
      })(Button);

    function setMainPage() {
        window.location.reload(false);
    }

    async function getDLData() {
        fetch('../mafp_dlfile.json')
        .then(res => res.json()) 
        .then(data => {
            console.log(data);
            setDLData(data);
        })
   }
   

    useEffect(() => {
       getDLData();
    }, []); 

    return(
        <div className="standard-page">

            <AppBar position="static"  style={{ background: 'transparent', boxShadow: 'none'}}>
                <StyledButtonTop><Link style={{ color: "#24D888",textDecoration: 'none' }} to="/">MY AD-FREE PLAYER</Link></StyledButtonTop>
            </AppBar>

            <div className="Container-centered">
                <h1>Downloads:</h1>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong><u>Platform</u></strong></TableCell>
                                <TableCell><strong><u>Description</u></strong></TableCell>
                                <TableCell><strong><u>Link</u></strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <img alt="" width="150px" src={logoWindows}  />
                                </TableCell>
                                <TableCell>
                                    <Typography><b>The official "My Ad-Free Player" installer for Windows</b><br /><br />
                                    (Note: if a Window pops up saying that Windows Protected Your PC, this is only because this program
                                    is relatvely new, there are no problems with this program. Just click the 'More Info" link in that window
                                    and then the Run Anyway button to install. The program installer has been scanned and is free of any 
                                    malware or other problems before release. Microsoft is cautious of programs until they reach a certain 
                                    number of downloads and installs. This message should eventually go away.</Typography>                                    
                                </TableCell>
                                <TableCell>
                                   <a href={dlData.link}>
                                       <Button variant="contained" color="primary">Download {dlData.version}</Button>
                                    </a><br /><br />
                                    <a href="changelog.txt">
                                        changelog                    
                                    </a>
                                </TableCell>
                            </TableRow>     
                                    
                            <TableRow>
                                <TableCell>
                                    <img alt="" src={logoPDF} />
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <b>The Quickstart guide in PDF format for the Windows version {dlData.version}</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                   <a href={dlData.pdfqswin}>
                                       <Button variant="contained" color="primary">Download quickstart guide</Button>
                                       </a>
                                </TableCell>
                            </TableRow>
 
                            <TableRow>
                                <TableCell>
                                    <img alt="" src={logoRoku}  />
                                </TableCell>
                                <TableCell>
                                    <Typography>Roku version Coming Soon</Typography>                                    
                                </TableCell>
                                <TableCell>
                                   <a href="/download">&nbsp;</a>                                  
                                </TableCell>
                            </TableRow>     

                            <TableRow>
                                <TableCell>
                                    <img alt="" src={logoAndroid}  />
                                </TableCell>
                                <TableCell>
                                    <Typography>Android  version Coming Soon</Typography>                                    
                                </TableCell>
                                <TableCell>
                                   <a href="">&nbsp;</a>                                  
                                </TableCell>
                            </TableRow>     
                        </TableBody>
                   </Table>
                </TableContainer>
            </div>
        </div>
    );
}


const Download = (props) => (
    <DownloadForm />
);

export default Download;