import React from "react";
import {Link} from "react-router-dom";

function Navbar() {
  return (
    <div>
      <Link to="/">Home </Link>
      <Link to="/download">Download </Link>
      <Link to="/signin">Sign In </Link>
    </div>
  );
};

export default Navbar;