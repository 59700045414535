import React, { useState } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements,  useStripe,  useElements } from '@stripe/react-stripe-js';

import '../App.css';
import '../stripe/global.css';
import '../stripe/index.css';
import '../stripe/normalize.css';
import { TableBody } from '@material-ui/core';

let baseURL = '';
let stripePromise;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const DEBUG = 0;            
if(DEBUG === 1) {
    baseURL = 'http://localhost:5000/';            // TODO: change this to production AWS server for production release
    stripePromise = loadStripe("pk_test_51HJjixBXxLTZ2ERwEkGVDG3VuJjZsbdtcqVjoPTzvuahWjDqbclz7eLv4iRva1orsTXWJmbwJiFp0dZfxT1JgSfy00ARlKdBbI");
} else {
    //baseURL = 'https://ec2-52-14-74-68.us-east-2.compute.amazonaws.com:443/';            // TODO: change this to production AWS server for production release
    baseURL = 'https://myadfreeplayer.xyz/';            // TODO: change this to production AWS server for production release
    stripePromise = loadStripe("pk_live_51HJjixBXxLTZ2ERwEijOOAv3gWrcdA826R9peZs6xugrStvhBMQrohIxmnVfBI5xYLhOyum4fMySshKk4qWdvNva00b5IEJOgQ");
}

console.log("baseURL 1 = " + baseURL);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe("pk_test_51HJjixBXxLTZ2ERwEkGVDG3VuJjZsbdtcqVjoPTzvuahWjDqbclz7eLv4iRva1orsTXWJmbwJiFp0dZfxT1JgSfy00ARlKdBbI");
/*
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error('**Stripe publishable key environment variable not set**');
  console.error('**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**');
  console.error('**Replace .env.example with .env and **');
}
*/
export const CheckoutForm = ({ productSelected, customer, setNextStep, gCID }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(0);
  const [accountInformation, setAccountInformation] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState('');

  if(customer.id === 0) {
    customer.id = gCID;
    console.log("patch CID with " + gCID);
  }
  //console.log("customer id/license to create: " + customer.id + ", " + customer.licenseID);
  //console.log("Payment form, customer: " + JSON.stringify(customer));

  function   handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    console.log("handlePaymentThatRequiresCustomerAction - " + subscription.status);
    if (subscription && (subscription.status === 'active' || subscription.status === 200 || subscription.status === "200")) {
      // subscription is active, no customer actions required.
      console.log('handlePaymentThatRequiresCustomerAction: status = active');
      return { subscription, priceId, paymentMethodId };
    }

    if(subscription.status === "404") {
  //      console.log("error: " + JSON.stringify(subscription));
        return {subscription, priceId, paymentMethodId };
    }
    console.log("------1")
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) 
    {
    //console.log("handleRequiresPaymentMethod: " + JSON.stringify(subscription));
    if (typeof subscription.status === 'undefined' || subscription.latest_invoice === 'undefined'){
        console.log("-2");
        return { subscription, priceId, paymentMethodId };
    }
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      console.log(" - active");
      return { subscription, priceId, paymentMethodId };
    } else {
        //console.log("__)___")
        if( !subscription || !subscription.latest_invoice ) {
            console.log('weird')
            return { subscription, priceId, paymentMethodId };
        }
        if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
            console.log(" - requires payment method");
            // Using localStorage to store the state of the retry here
            // (feel free to replace with what you prefer)
            // Store the latest invoice ID and status
            localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
            localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status
            );
            console.log("card declined")
            throw new Error('Your card was declined.');
        } 
        else 
        {
            return { subscription, priceId, paymentMethodId };
        }
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    //console.log("-----2")
    const priceId = productSelected.name.toUpperCase();
    return (
      fetch('/retry-invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customer.id,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error);
          console.log("error 0")
          // An error has happened. Display the failure to the user here.
          setSubscribing(0);
          setErrorToDisplay(error && error.error && error.error.decline_code);
        })
    );
  }

  function onSubscriptionComplete(result) {
    console.log("onSubscriptionComplete"); //result = " + JSON.stringify(result));
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }

    setAccountInformation(result);
    setSubscribing(2);
    setNextStep(3);                                                 // hide the <BACK> button if we got here
    alert("You have sucessfully Subscribed!");

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }


  function createSubscription({ paymentMethodId }) {
    //const priceId = productSelected.name.toUpperCase();
    //const customerId = customer.id;
    let email;
    // change toi always sent email, even if restarting, in case they change computers, have unintalled the program or lost the email or whatever
//    if(customer.restarting === true) {
//      email = 
//    } else {
      email = customer.email;
//    }
    const pw = customer.password;
    const licID = customer.licenseID;
    //const priceId = "";
    const priceAmt = productSelected.amount;
    const trialEnd = customer.trialEnd;
    const url = baseURL + 'stripe/create-subscription';

    //console.log("baseURL 2 = " + baseURL);
    //console.log("createSubscription called: priceAnt = " + priceAmt + ", customerId = " + customer.id + ", paymentMethodId = " + paymentMethodId + ", license = " + licID + ", trialend = " + trialEnd );

    return (

        axios.post(url, {             
               customerId: customer.id,
               paymentMethodId: paymentMethodId,
               priceAmt: priceAmt,
               email: email,
               password: pw,
               licenseID: licID,
               trialEnd: trialEnd,
            })

/*
      fetch('/stripe/create-subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customer.id,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
*/

        .then((response) => {
          //console.log("got response " + JSON.stringify(response.data));
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
           console.log("1st result");
          if (result.error) {
            console.log("found error in 1st result: " + result.error);
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
            console.log("Got result form create_subscription call, no errors");
            //console.log("also sending email (" + email + "), password (" + pw + "), and licenseID (" + licID + ")");
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.name,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.

          // only do this if the error isn't an empty object
          if (Object.keys(error).length !== 0) {
            console.log("not empty error object");
            console.log("error 1: " + JSON.stringify(error));
            setSubscribing(0);
            const errMsg = "Credit Card failed, please double check or try another"; // (" + error.error.decline_code + ")";
          //setErrorToDisplay(error.message || error.error.decline_code);
            setErrorToDisplay(errMsg)
          } else {
            console.log("empty error object, assuming no error");
          }
        })
    );
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    //console.log(" customer: " + JSON.stringify(customer));
    //console.log(" productSelected: " + JSON.stringify(productSelected));
    setErrorToDisplay("");
    setSubscribing(1);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
   
    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[createPaymentMethod error] ', error);
      setSubscribing(0);
      console.log("error 2")
      setErrorToDisplay(error && error.message);
      return;
    } else {
        console.log("No error on createPaymentMethod")
    }
    console.log('[PaymentMethod] ', paymentMethod);
    const paymentMethodId = paymentMethod.id;
    console.log("status: " + latestInvoicePaymentIntentStatus);

    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId');
      console.log('retrying invoice with new payment method');
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      });
      return;
    }
    //return
    // Create the subscription
    console.log("trying to create subscription now with customerID: " + customer.id + ", paymentMethodId: " + paymentMethodId);
    createSubscription({
       paymentMethodId
    });

    
  };
/*
          <div className="font-bold text-xl mb-2">
            Enter your card details. <br />
            Your subscription will start now.
          </div>
           className="w-full bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
*/ 
  if (accountInformation) {
    return (
        <div>
          <br /><br />
          <Typography><strong>Thank you for Subscribing!<br/><br />
          Please check your email inbox for instructions on how to activate the Player on your device!</strong>
          <br />
          (Be sure to check your Spam folder, in case it was filterd out. It will be from SkySled)
          </Typography>
          
          <br /><br />
        </div>
    );
  } else {
 
    return (
      <div className="Stripe-payment_container">
    
          <div className="text-gray-700 text-base">
            <TableContainer>
                <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell className="table-cell-left">
                        <Typography>Total due now </Typography>
                        </TableCell>
                        <TableCell className="table-cell-right">
                            <strong>{productSelected.price}</strong>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
          </div><br />
          <div className="text-gray-700 text-base mb-4">
          <TableContainer>
                <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className="table-cell-left">
                        <Typography>Subscribing to</Typography>
                        </TableCell>
                        <TableCell className="table-cell-right">
                            <strong>{productSelected.name}</strong>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            
            
          </div>     
        <div id="payment-form" className="flex justify-center">
        <div className="w-full inline-block border rounded-md">

          <div className="w-full">
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full px-3 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                <Typography>Full name</Typography> &nbsp;
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 border rounded-md py-3 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="name"
                  type="text"
                  placeholder="First and last name"
                  required
                />
              </div>
            </div>
            <form id="payment-form" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3 mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  <Typography>Card</Typography>
                  </label>
                  <div
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                    id="card-element"
                  >
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#32325d',
                            fontFamily:
                              '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                            '::placeholder': {
                              color: '#a0aec0',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="text-gray-700 text-base mt-2" role="alert">
                    {errorToDisplay ? errorToDisplay : null}
                  </div>
                </div>
              </div>
              <button
                id="submit-premium"
                className="info-box2"
                type="submit"
              >
                <div className="">
                  <div>{subscribing===1 ? 'Subscribing...' : (subscribing===0? 'Subscribe' : 'Subscribed!')}</div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    
      </div>
    );
  }
};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;